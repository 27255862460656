<!--  TODO： 挂载，云台吊舱弹框组件-->
<template>
  <div class="cpt-MMC_Gimbal_Z40" :style="containerStyle">
    <div class="hd" v-interact>
      <div class="left">
        <!-- <img src="~@/assets/images/mount/mount_head.png" /> -->
        <!-- <div class="title">高清变焦相机</div> -->
        <div class="title">
          <div class="tab tab1">
            <span class="text">云台吊舱</span>
          </div>
          <!-- <div class="tab tab2">
            <span class="text">爆闪灯</span>
          </div> -->
        </div>

        <div @click="$emit('close')" class="close">
          <img class="img" src="@/assets/img/i40.png" alt="">
        </div>
      </div>
    </div>
    <div class="bd">
      <div class="form-wrap">
        <!-- 云台模式 -->
        <div class="form-item form-item-yuntai">
          <div class="label-box">云台模式</div>
          <div class="content">
            <div class="moshi moshi-suoding" @click="handle_change_ptz_mode(1)"
              :class="[holderModelDom == 1 ? 'moshi-selected' : '']">锁定模式</div>
            <div class="moshi moshi-gensui" @click="handle_change_ptz_mode(2)"
              :class="[holderModelDom == 2 ? 'moshi-selected' : '']">跟随模式</div>
          </div>
          <!-- <div class="input-box">
            <div class="mono">
              <div class="mono-long">
                <div class="mono-left mono_box" :class="{ active: holderModelDom == 0 }"
                  @click="handle_change_ptz_mode(0)"></div>
                <div class="mono-middle mono_box" :class="{ active: holderModelDom == 1 }"
                  @click="handle_change_ptz_mode(1)"></div>
                <div class="mono-right mono_box" :class="{ active: holderModelDom == 2 }"
                  @click="handle_change_ptz_mode(2)"></div>
              </div>
              <div class="text">
                <div class="text-left">锁定</div>
                <div class="text-right">跟随</div>
                <div class="text-right">回中</div>
              </div>
            </div>
          </div> -->
        </div>
        <!-- 视频指点 -->
        <div class="form-item form-item-shipinzhidian" v-if=false>
          <div class="label-box">视频指点</div>
          <div class="input-box content">
            <el-switch v-model="dj_mode" @change="handle_change_dj_mode" active-color="#5CC13C" inactive-color="#2E4B7F"
              active-text="开" inactive-text="关">
            </el-switch>
            <!-- <el-radio-group @change="handle_change_dj_mode" v-model="dj_mode">
              <el-radio size="mini" v-for="item in drd.dj_mode" :key="item.id" :label="item.id">{{ item.label
              }}</el-radio>
            </el-radio-group> -->
          </div>
        </div>
        <!-- 变焦 -->
        <div class="form-item form-item-bianjiao">
          <div class="label-box">
            <span>变</span>
            <span>焦</span>
          </div>
          <div class="input-box content">
            <div class="mr10 mrplus" style="cursor: pointer" @mousedown="handle_zoom(-1)" @mouseup="stopChange">
              <img src="@/assets/img/i44.png" alt="">
            </div>
            <el-slider class="slider" v-model="newZoom" :max="10" :min="-10" :show-tooltip="false"
              @change="stopChange"></el-slider>
            <div class="ml10 mradd" style="cursor: pointer" @mousedown="handle_zoom(1)" @mouseup="stopChange">
              <img src="@/assets/img/i45.png" alt="">
            </div>
            <div class="slider-input">
              <el-input v-model.number="newZoom" placeholder="" disabled></el-input>
            </div>
          </div>
        </div>
        <!-- 俯仰 -->
        <div class="form-item form-item-fuyang">
          <div class="label-box">俯仰</div>
          <div class="input-box content">
            <div class="item item1" :class="[pitch == -30 ? 'item-selected' : '']" @click="handle_change_pitch(-30)">30
            </div>
            <el-divider class="divider" direction="vertical"></el-divider>

            <div class="item item2" :class="[pitch == -60 ? 'item-selected' : '']" @click="handle_change_pitch(-60)">60
            </div>
            <el-divider class="divider" direction="vertical"></el-divider>

            <div class="item item3" :class="[pitch == -90 ? 'item-selected' : '']" @click="handle_change_pitch(-90)">90
            </div>

            <!-- item-selected -->
            <!-- <div class="speedC">
              <div class="pitch" :style="pitch == item.num
                ? 'background: #FFFFFF;color: #333333;'
                : ''
                " v-for="(item, index) in regList" :key="index" @click="handle_change_pitch(item.num)">{{ item.val }}
              </div>
            </div> -->
          </div>
        </div>
        <!-- 云台复位 -->
        <div class="form-item form-item-yuntaifuwei">
          <div class="label-box">
            <span class="name"> 云台复位 </span>
          </div>
          <div class="input-box content">
            <div class="item item1" :class="[photoVideo == 0 ? 'item-selected' : '']" @click="handle_take_photo(0)">
              <img v-if="photoVideo == 0" src="@/assets/img/i47.png" alt="">
              <img v-else src="@/assets/img/i130.png" alt="">
              <span class="name">拍照</span>
            </div>

            <div class="item item2" :class="[photoVideo == 1 ? 'item-selected' : '']" @click="handle_record(1)">
              <img v-if="photoVideo == 1" src="@/assets/img/i46.png" alt="">
              <img v-else src="@/assets/img/i132.png" alt="">
              <span class="name">摄影</span>
            </div>

          </div>
        </div>

        <div class="form-item" v-if="false">
          <div class="label-box">控制速度:</div>
          <div class="input-box">
            <div class="speedC">
              <div class="speed" :style="control_speed == index + 1 ? 'background: #FFFFFF;color: #333333;' : ''"
                v-for="(item, index) in new Array(5).fill(1)" :key="index" @click="control_speed = index + 1">
                {{ index + 1 }}
              </div>
            </div>
          </div>
        </div>

        <div class="form-item" v-if="false">
          <div class="label-box">相机模式{{ offline }}</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_camera_mode" v-model="camera_mode">
              <el-radio size="mini" v-for="item in drd.camera_mode" :key="item.id" :label="item.id">{{ item.label
                }}</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
      <!-- 右侧 云台控制 -->
      <div class="rocker-wrap">
        <div class="item-group" v-if="false">
          <div @click="handle_take_photo" class="item-box">
            <el-tooltip class="item" effect="dark" content="拍照" placement="top">
              <div class="icon-box">
                <!-- <span class="iconfont icon-paizhao1"></span> -->
                <img src="~@/assets/images/mount/photo.png" alt />
              </div>
            </el-tooltip>
          </div>
          <div @mousedown="handle_record" @mouseup="handle_recordStop" class="item-box">
            <el-tooltip class="item" effect="dark" content="录像" placement="top">
              <div class="icon-box">
                <!-- <span class="iconfont icon-dandianshipin"></span> -->
                <img src="~@/assets/images/mount/record.png" alt />
              </div>
            </el-tooltip>
          </div>
        </div>

        <div class="rocker" ref="rocker">
          <span class="title">云台控制</span>
          <img src="@/assets/img/i48.png" alt="">
          <!-- <div class="shangUp" @mousedown="fangxiang('up')" @mouseup="stopfxFn" @mouseout="stopfxFn"></div>
          <div class="xaiUp" @mousedown="fangxiang('down')" @mouseup="stopfxFn" @mouseout="stopfxFn"></div>
          <div class="zuoUp" @mousedown="fangxiang('right')" @mouseup="stopfxFn" @mouseout="stopfxFn"></div>
          <div class="youUp" @mousedown="fangxiang('left')" @mouseup="stopfxFn" @mouseout="stopfxFn"></div> -->
          <img class="arrow arrow1" src="@/assets/img/i38.png" alt="" @mousedown="fangxiang('up')" @mouseup="stopfxFn">
          <img class="arrow arrow2" src="@/assets/img/i38.png" alt="" @mousedown="fangxiang('down')" @mouseup="stopfxFn">
          <img class="arrow arrow3" src="@/assets/img/i38.png" alt="" @mousedown="fangxiang('right')" @mouseup="stopfxFn">
          <img class="arrow arrow4" src="@/assets/img/i38.png" alt="" @mousedown="fangxiang('left')" @mouseup="stopfxFn">
        </div>

        <!-- 控制速度 -->
        <div class="control-speed">
          <span class="title">控制速度</span>
          <div class="content">
            <div class="item" :class="[control_speed == 1 ? 'item-selected' : '']" @click="control_speed = 1">1</div>
            <el-divider class="divider" direction="vertical"></el-divider>
            <div class="item" :class="[control_speed == 2 ? 'item-selected' : '']" @click="control_speed = 2">2</div>
            <el-divider class="divider" direction="vertical"></el-divider>
            <div class="item" :class="[control_speed == 3 ? 'item-selected' : '']" @click="control_speed = 3">3</div>
            <el-divider class="divider" direction="vertical"></el-divider>
            <div class="item" :class="[control_speed == 4 ? 'item-selected' : '']" @click="control_speed = 4">4</div>
            <el-divider class="divider" direction="vertical"></el-divider>
            <div class="item" :class="[control_speed == 5 ? 'item-selected' : '']" @click="control_speed = 5">5</div>
          </div>
          <div class="bottom-line">
            <div class="item" :class="[control_speed == 1 ? 'item-selected' : '']"></div>
            <!-- <el-divider class="divider" direction="vertical"></el-divider> -->
            <div class="item" :class="[control_speed == 2 ? 'item-selected' : '']"></div>
            <!-- <el-divider class="divider" direction="vertical"></el-divider> -->
            <div class="item" :class="[control_speed == 3 ? 'item-selected' : '']"></div>
            <!-- <el-divider class="divider" direction="vertical"></el-divider> -->
            <div class="item" :class="[control_speed == 4 ? 'item-selected' : '']"></div>
            <!-- <el-divider class="divider" direction="vertical"></el-divider> -->
            <div class="item" :class="[control_speed == 5 ? 'item-selected' : '']"></div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { cal_crc_table, MMC_Gimbal_Z40 } from "../utils";
export default {
  props: {
    containerStyle: {
      type: Object,
      default: () => ({}),
    },
    position: {
      type: Object,
      default: () => ({}),
    },
    device: {
      type: Object,
      default: () => ({}),
    },
    payload: {
      type: Array,
      default: () => [],
    },
    selected_mount: {
      type: Object,
      default: () => ({}),
    },
    moveType: {
      type: String,
      default: () => "",
    },
    offline: {
      type: Boolean,
      default: () => true,
    },
    mounts: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      // value: 20,
      holderModelDom: 1,
      radio: 1,
      value: 25,
      value2: 25,
      control_speed: 3,
      ptz_mode: 0,

      // 变焦
      zoom: 1,
      zoomTimer: null,
      zoomFlag: false,
      newZoom: 1,

      pitch: -30,
      yaw: 0,
      camera_mode: 0,
      record: false,
      dj_mode: 0,
      regList: [
        {
          val: "复位",
          num: 0,
        },
        {
          val: "30",
          num: -30,
        },
        {
          val: "60",
          num: -60,
        },
        {
          val: "90",
          num: -90,
        },
      ],
      drd: {
        dj_mode: [
          { id: 0, label: "关闭" },
          { id: 1, label: "打开" },
        ],
        ptz_mode: [
          { id: 0, label: "锁定" },
          { id: 1, label: "跟随" },
          { id: 2, label: "回中" },
        ],
        camera_mode: [
          { id: 0, label: "录像" },
          { id: 2, label: "拍照" },
        ],
      },
      timer: null,
      time: null,
      seq: 0,
      photoVideo: -1,
      recordFlag: false,
      recordTimer: null,
      flag: false,
    };
  },
  watch: {
    payload: {
      handler(value) {
        this.resolve_payload(value);
      },
      deep: true,
    },
  },
  mounted() {
    console.log(this.mounts);

  },
  beforeDestroy() {
    this.$store.commit("device/SET_MOVE_DATA", {
      ...this.device,
      mountStatus: 0,
    });
  },
  methods: {
    // 视频指点 dj_mode 0 关 ， 1 开
    // id false 关 ， true 开
    handle_change_dj_mode(idd) {
      let id = 0;
      if (idd) {
        id = 1;
      } else {
        id = 0;
      }
      // console.log(id, 'iddddddddddddddddd');
      // console.log(this.selected_mount, "selected_mount");
      // return;
      this.$store.commit("device/SET_MOVE_DATA", {
        ...this.device,
        mountStatus: id,
        mountId: this.selected_mount.mountId || this.selected_mount.mountType,
        moveType: this.moveType,
        name: this.selected_mount.name,
      });
    },
    onChangezoom(e) {
      if (this.value2 < this.value) {
        this.handle_zoom(1);
      } else if (this.value2 > this.value) {
        this.handle_zoom(-1);
      }
      this.value2 = e;
    },
    // 变焦 鼠标抬起 停止
    stopChange1() {
      // this.value = 25;
      // this.value += v;
      // this.value2 = 25;
      // this.handle_zoom(this)
      let buffer = MMC_Gimbal_Z40.zoom((this.zoom = 1));
      this.commit_directive(buffer);
    },
    /**
     *@Description: 变焦 鼠标抬起事件
     *@Date: 2023-10-17 10:09:14
     *@Params1: 
     *@Return1: 
    */
    stopChange() {
      this.value = 25;
      this.value2 = 25;
      this.zoomFlag = false;
      clearTimeout(this.zoomTimer);
      let newParams = {
        zoomRate: 0,
        method: 'camera_zoom_rate',
        cmdName: 'UAT_MOUNT_Z40TIR',
      }
      let buffer = null;
      this.commit_directive(buffer, newParams);
      this.stopRun(500);
      this.commit_directive(buffer, newParams);
      this.stopRun(500);
      this.commit_directive(buffer, newParams);
      this.newZoom = 0;
    },
    /**
     *@Description: 变焦鼠标拖动滑块
     *@Date: 2023-10-17 10:49:32
     *@Params1: 
     *@Return1: 
    */
    bjSliderInputHandle(v) {
      this.handle_zoom(0);
    },
    resolve_payload(buff) {
      try {
        let dataArray = new Uint8Array(buff);
        // console.log(dataArray,'dataArray');
        if (
          dataArray[0] === 0xa5 &&
          cal_crc_table(dataArray) === dataArray[dataArray.length - 1]
        ) {
          switch (dataArray[1]) {
            case 0x01:
              this.msg_key_status1(dataArray);
              break;
            case 0xf8:
              this.gcs_button_ctrl(dataArray);
              break;
          }
        }
      } catch (error) { }
    },
    gcs_button_ctrl(data) {
      let button = data[3];
      let status = data[4];

      switch (button) {
        case 4:
          this.gimbal_mode_ctrl(status);
          break;
        default:
          break;
      }
    },
    msg_key_status1(data) {
      this.gimbal_mode_ctrl(data[7] & 0x3);
    },
    gimbal_mode_ctrl(mode) {
      this.ptz_mode = mode;
    },
    // 云台模式
    handle_change_ptz_mode(value) {
      // 1 锁定， 2 跟随， 0 回中，回中就是复位，另外一个接口
      this.holderModelDom = value;
      let buffer = MMC_Gimbal_Z40.gimbal_mode_ctrl(value);
      let newParams = {};
      if (value == 1 || value == 2) {
        newParams = {
          value: value,
          method: 'camera_mode',
          cmdName: 'UAT_MOUNT_Z40TIR',
        }
      } else {
        // 云台复位发送云台俯仰、航向角度控制协议 pitch 0 yaw 0
        newParams = {
          pitch: 0,
          yaw: 0, //航向角度界面没有设置为0
          method: 'gimbal_cmd_degree',
          cmdName: 'UAT_MOUNT_Z40TIR',
        }
      }
      this.commit_directive(buffer, newParams);
      // this.commit_directive(buffer);
    },
    // 变焦 鼠标按下
    handle_zoom1(value) {
      this.value = parseInt(this.value) + value;
      let buffer = MMC_Gimbal_Z40.zoom(this.zoom + value);
      this.commit_directive(buffer);
      // buffer = MMC_Gimbal_Z40.zoom((this.zoom = 1));
      // this.commit_directive(buffer);
    },
    /**
     *@Description: 变焦 鼠标按下事件
     *@Date: 2023-10-17 10:05:23
     *@Params1: 
     *@Return1: 
    */
    handle_zoom(value) {
      this.zoomFlag = true;
      if (this.zoomFlag) {
        this.zoomTimer = setTimeout(() => {
          this.handle_zoom(value);
        }, 500);
      }
      console.log(value, 'valuevaluevalue');
      this.newZoom += value;
      console.log(this.newZoom, 'valuevaluevalue');
      if (this.newZoom > 10 || this.newZoom < -10) {
        this.$message({
          type: 'warning',
          message: '变焦速度大小在[-10, 10]区间'
        });
      } else {
        let newParams = {
          zoomRate: this.newZoom,
          method: 'camera_zoom_rate',
          cmdName: 'UAT_MOUNT_Z40TIR',
        }
        let buffer = MMC_Gimbal_Z40.zoom(this.newZoom + value);
        this.commit_directive(buffer, newParams);
      }
    },
    // 俯仰
    handle_change_pitch1(num) {
      this.pitch = num;
      let buffer = MMC_Gimbal_Z40.change_pitch_angle(this.pitch);
      this.commit_directive(buffer);
    },
    /**
     *@Description: 俯仰
     *@Date: 2023-10-17 10:57:03
     *@Params1: 
     *@Return1: 
    */
    handle_change_pitch(num) {
      this.pitch = num;
      let buffer = MMC_Gimbal_Z40.change_pitch_angle(this.pitch);
      let newParams = {
        pitch: 0, //航向角度界面没有设置为0
        yaw: num,
        method: 'gimbal_cmd_degree',
        cmdName: 'UAT_MOUNT_Z40TIR',
      }
      this.commit_directive(buffer, newParams);
    },
    handle_change_yaw() {
      let buffer = MMC_Gimbal_Z40.change_yaw_angle(this.yaw);
      this.commit_directive(buffer);
    },
    handle_change_camera_mode(value) {
      let buffer = MMC_Gimbal_Z40.camera_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    // 云台复位拍照
    handle_take_photo1() {
      this.photoVideo = 0;
      let { position } = this;
      let buffer = MMC_Gimbal_Z40.take_photo(position || {});
      this.commit_directive(buffer);
      this.$emit("take_photo", "MMC_Gimbal_Z40");
    },
    /**
     *@Description: 拍照
     *@Date: 2023-10-17 10:59:48
     *@Params1: 
     *@Return1: 
    */
    handle_take_photo() {
      // this.photoVideo = 0;
      // let { position } = this;
      // let buffer = MMC_Gimbal_Z40.take_photo(position || {});
      // this.commit_directive(buffer);
      // this.$emit("take_photo", "MMC_Gimbal_Z40");
      let { position } = this;
      let buffer = MMC_Gimbal_Z40.take_photo(position || {});
      let newParams = {
        method: 'capture_control',
        cmdName: 'UAT_MOUNT_Z40TIR',
      }
      this.commit_directive(buffer, newParams);
      this.$emit("take_photo", "MMC_Gimbal_Z40");
    },
    // 云台复位 摄影
    handle_record1() {
      this.photoVideo = 1;
      this.record = !this.record;
      let buffer = MMC_Gimbal_Z40.record(this.record);
      this.commit_directive(buffer);
    },

    // 相机模式录像
    handle_record() {
      for (let i = 0; i < 3; i++) {
        this.record = !this.record;
        let buffer = MMC_Gimbal_Z40.record(this.record);
        this.recordFlag = true;
        // clearTimeout(this.recordTimer);
        // if (this.recordFlag) {
        //   this.recordTimer = setTimeout(() => {
        //     this.handle_record();
        //   }, 500);
        // }
        let newParams = {
          method: 'video_control',
          cmdName: 'UAT_MOUNT_Z40TIR',
          videoState: 1,
        }
        this.commit_directive(buffer, newParams);
        this.stopRun(500);
      }


      // this.commit_directive(buffer);
    },
    // 录像停止
    handle_recordStop() {
      console.log('up');

      this.recordFlag = false;
      clearInterval(this.recordTimer);
      let newParams = {
        method: 'video_control',
        cmdName: 'UAT_MOUNT_Z40TIR',
        videoState: 0,
      }
      let buffer = null;
      this.commit_directive(buffer, newParams);
      this.stopRun(500);
      this.commit_directive(buffer, newParams);
      this.stopRun(500);
      this.commit_directive(buffer, newParams);
    },
    commit_directive(buffer) {
      console.log(buffer);
      let p = arguments.length > 1 ? arguments[1] : '';
      this.$emit("directive", buffer, p);
    },
    fangxiang1(type) {
      clearTimeout(this.time);
      let buffer = null;
      this.flag = true;
      if (this.flag) {
        this.time = setTimeout(() => {
          this.fangxiang(type);
        }, 500);
      }
      switch (type) {
        case "up":
          buffer = MMC_Gimbal_Z40.gimbal_pitch_ctrl(1, this.control_speed);
          this.commit_directive(buffer);
          break;
        case "left":
          buffer = MMC_Gimbal_Z40.gimbal_yaw_ctrl(1, this.control_speed);
          this.commit_directive(buffer);
          break;
        case "right":
          buffer = MMC_Gimbal_Z40.gimbal_yaw_ctrl(-1, this.control_speed);
          this.commit_directive(buffer);
          break;
        case "down":
          buffer = MMC_Gimbal_Z40.gimbal_pitch_ctrl(-1, this.control_speed);
          this.commit_directive(buffer);
      }
      // this.timer = setTimeout(() => {
      //   clearTimeout(this.timer)
      //   buffer = MMC_Gimbal_Z40.gimbal_pitch_ctrl(0, this.control_speed);
      //   this.commit_directive(buffer);
      //   buffer = MMC_Gimbal_Z40.gimbal_yaw_ctrl(0, this.control_speed);
      //   this.commit_directive(buffer);
      // }, 500);
    },
    stopfxFn1() {
      this.flag = false;
      clearTimeout(this.time);
      let buffer = null;
      buffer = MMC_Gimbal_Z40.gimbal_pitch_ctrl(0, this.control_speed);
      this.commit_directive(buffer);
      buffer = MMC_Gimbal_Z40.gimbal_yaw_ctrl(0, this.control_speed);
      this.commit_directive(buffer);
    },

    // 速度控制
    fangxiang(type) {
      // console.log(type, 'yawwwwwwwwwwwwww-0');
      clearTimeout(this.time);
      let buffer = null;
      this.flag = true;
      if (this.flag) {
        this.time = setTimeout(() => {
          this.fangxiang(type);
        }, 500);
      }
      let a = 0;
      let newParams = {};
      switch (type) {
        case "up":
          this.seq += 1;
          a = this.control_speed * 2;
          newParams = {
            seq: this.seq,
            pitchSpeed: a,
            yawSpeed: 0,
            method: 'gimbal_cmd_speed',
            cmdName: 'UAT_MOUNT_Z40TIR',
          }
          buffer = MMC_Gimbal_Z40.gimbal_pitch_ctrl(1, this.control_speed);
          // this.commit_directive(buffer);
          this.commit_directive(buffer, newParams);
          break;
        case "left":
          this.seq += 1;
          a = this.control_speed * 2;
          newParams = {
            seq: this.seq,
            pitchSpeed: 0,
            yawSpeed: a,
            method: 'gimbal_cmd_speed',
            cmdName: 'UAT_MOUNT_Z40TIR',
          }
          buffer = MMC_Gimbal_Z40.gimbal_yaw_ctrl(1, this.control_speed);
          // this.commit_directive(buffer);
          console.log('newParams', newParams);
          
          this.commit_directive(buffer, newParams);
          break;
        case "right":
          this.seq += 1;
          a = this.control_speed * (-2);
          newParams = {
            seq: this.seq,
            pitchSpeed: 0,
            yawSpeed: a,
            method: 'gimbal_cmd_speed',
            cmdName: 'UAT_MOUNT_Z40TIR',
          }
          buffer = MMC_Gimbal_Z40.gimbal_yaw_ctrl(-1, this.control_speed);
          // this.commit_directive(buffer);
          this.commit_directive(buffer, newParams);
          break;
        case "down":
          this.seq += 1;
          a = this.control_speed * (-2);
          newParams = {
            seq: this.seq,
            pitchSpeed: a,
            yawSpeed: 0,
            method: 'gimbal_cmd_speed',
            cmdName: 'UAT_MOUNT_Z40TIR',
          }
          buffer = MMC_Gimbal_Z40.gimbal_pitch_ctrl(-1, this.control_speed);
          // this.commit_directive(buffer);
          this.commit_directive(buffer, newParams);
      }
      // this.timer = setTimeout(() => {
      //   clearTimeout(this.timer)
      //   buffer = MMC_Gimbal_Z40.gimbal_pitch_ctrl(0, this.control_speed);
      //   this.commit_directive(buffer);
      //   buffer = MMC_Gimbal_Z40.gimbal_yaw_ctrl(0, this.control_speed);
      //   this.commit_directive(buffer);
      // }, 500);
    },
    stopfxFn() {
      this.flag = false;
      clearTimeout(this.time);
      let buffer = null;
      buffer = MMC_Gimbal_Z40.gimbal_pitch_ctrl(0, this.control_speed);
      // this.commit_directive(buffer);

      buffer = MMC_Gimbal_Z40.gimbal_yaw_ctrl(0, this.control_speed);
      // this.commit_directive(buffer);

      this.stopRun(500);

      this.seq += 1;
      let newParams = {
        seq: this.seq,
        pitchSpeed: 0,
        yawSpeed: 0,
        method: 'gimbal_cmd_speed',
        cmdName: 'UAT_MOUNT_Z40TIR',
      }
      this.commit_directive(buffer, newParams);

      this.stopRun(500);

      this.seq += 1;
      newParams = {
        seq: this.seq,
        pitchSpeed: 0,
        yawSpeed: 0,
        method: 'gimbal_cmd_speed',
        cmdName: 'UAT_MOUNT_Z40TIR',
      }
      this.commit_directive(buffer, newParams);

      this.stopRun(500);

      this.seq += 1;
      newParams = {
        seq: this.seq,
        pitchSpeed: 0,
        yawSpeed: 0,
        method: 'gimbal_cmd_speed',
        cmdName: 'UAT_MOUNT_Z40TIR',
      }
      this.commit_directive(buffer, newParams);

      this.seq = 0;
    },
    // 暂停 n 毫秒
    stopRun(n) {
      n = n || 500;
      let now = new Date().getTime();
      let flag = true;
      while (flag) {
        let current = new Date().getTime();
        if (current - now > n) {
          flag = false;
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.cpt-MMC_Gimbal_Z40 {
  position: absolute;
  top: 0px;
  right: 0px !important;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  // border: 1px solid rgba(26, 92, 246, 0.5);
  // border: 1px solid rgba(26, 92, 246, 0.5);
  backdrop-filter: blur(1px);
  width: 470px;
  height: 290px;
  box-sizing: border-box;
  // width: 585px;
  width: 587px;
  height: 315px;
  background: #19334D;
  border-radius: 4px;
  border-radius: 4px;
  // color: #ffff00;

  // padding: 10px 20px;
  .hd {
    // height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // background: rgba(16, 65, 215, 0.2);
    // box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    // border-radius: 10px 10px 0 0;
    width: 100%;
    height: 44px;
    // background: #007AFF;
    // border: 1px solid #121213;
    border-radius: 0;

    .left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      box-sizing: border-box;
      border-bottom: 1px solid #979797;

      .title {
        display: flex;
        align-items: center;

        .tab {
          width: 84px;
          height: 44px;
          background: #007AFF;
          border: 1px solid #121213;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          .text {
            width: 56px;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
          }
        }
      }

      .img {
        height: 24px;
        height: 24px;
        cursor: pointer;
        float: right;
      }
    }

    .close {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      margin-right: 8px;
      cursor: pointer;
    }
  }

  .bd {
    // padding: 10px 20px;
    padding: 0px 0;
    display: flex;
    justify-content: space-between;
    // height: 230px;
    height: auto;

    overflow-y: auto;
    overflow-x: hidden;

    .form-wrap {
      flex-shrink: 0;
      width: 62%;
      border-right: 1px solid #979797;

      .form-item {
        display: flex;
        align-items: center;
        height: 64px;
        padding: 0 10px 0 10px;
        box-sizing: border-box;


        .label-box {
          margin-right: 14px;
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          width: 24%;
          // width: 56px;
          // height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          // line-height: 20px;
        }

        .content {
          width: 76%;
        }

        .input-box {
          display: flex;
          align-items: center;

          .mono {
            display: flex;
            flex-direction: column;

            .mono-long {
              display: flex;
              justify-content: space-between;
              width: 168px;
              height: 17px;
              background: url("~@/assets/images/mount_long.png") no-repeat;
              background-size: 100%;
              margin-bottom: 5px;

              .mono_box {
                width: 28px;
                height: 13px;
                border-radius: 3px;
                margin-top: 2px;
                cursor: pointer;
              }

              .active {
                background: url("~@/assets/images/mount/mount_control.png") no-repeat !important;
                background-size: 100% 100%;
              }
            }

            .text {
              font-size: 14px;
              font-family: MicrosoftYaHei;
              color: #ffffff;
              display: flex;
              justify-content: space-between;
              font-size: 14px;
            }
          }
        }
      }

      .form-item-yuntai {
        border-bottom: 1px solid #2C4C74;

        .content {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .moshi {
            width: 83px;
            height: 28px;
            background: #2E4B7F;
            box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.11);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            margin-right: 2px;
            cursor: pointer;
            color: #FFFFFF;
          }

          .moshi-selected {
            background: #FFFFFF !important;
            color: #000000 !important;
          }
        }
      }

      .form-item-shipinzhidian {
        border-bottom: 1px solid #2C4C74;

        .content {
          justify-content: flex-end;
        }
      }

      .form-item-bianjiao {
        border-bottom: 1px solid #2C4C74;

        .label-box {
          display: flex;
          align-items: center;
          // justify-content:;
        }

        .content {
          justify-content: space-between;

          .slider-input {
            width: 55px !important;
            height: 28px;
            background: #10263B;
            border-radius: 2px;
          }

          .mrplus,
          .mradd {
            width: 30px;
            height: 30px;
            margin-top: 5px;
          }

          .slider {
            width: calc(100% - 120px);
          }
        }
      }

      .form-item-fuyang {
        border-bottom: 1px solid #2C4C74;

        .content {
          // background-color: #007AFF;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .item {
            width: 38px;
            height: 20px;
            background: #10263B;
            box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.11);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            cursor: pointer;
          }

          .item-selected {
            background-color: #fff;
            color: #000;
          }

          .divider {
            margin: 0 1px;
            background-color: #c0c0c0;
          }
        }
      }

      .form-item-yuntaifuwei {
        // border-bottom: 1px solid #2C4C74;

        .label-box {
          width: 84px;
          height: 30px;
          background: #2F4369;
          box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.11);
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;

          .name {
            width: 56px;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #00F5FF;
            line-height: 20px;
          }
        }


        .content {
          justify-content: flex-end;

          .item {
            width: 84px;
            height: 32px;
            background: #10263B;
            box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.11);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            color: #fff;
            cursor: pointer;

            span {
              width: 28px;
              height: 20px;
              font-size: 14px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #FFFFFF;
              line-height: 20px;
            }

            img {
              width: 18px;
              height: 12px;
            }
          }

          .item-selected {
            background: #2F4369;
          }

        }
      }
    }

    .rocker-wrap {
      flex: 1;
      width: 38%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      // background-color: #007AFF;
      height: 100%;
      // margin-top: 10%;

      .item-group {
        display: flex;
        justify-content: space-evenly;

        .item-box {
          .icon-box {
            width: 32px;
            height: 32px;
            background: #000000;
            border-radius: 32px;
            display: flex;
            align-items: center;
            justify-content: center;

            .iconfont {
              font-size: 24px;
              color: #dce9ff;
              cursor: pointer;
            }
          }
        }
      }

      .rocker {
        margin: 10px auto 0;
        width: 150px;
        height: 150px;
        position: relative;
        margin: 0 auto;
        // background: center url("~@/assets/img/i48.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 70%;
        position: relative;
        margin-top: 30px;

        .arrow {
          width: 16px;
          height: 16px;
          position: absolute;
          cursor: pointer;
        }

        .arrow1 {
          top: 76px;
          left: 23px;
        }

        .arrow2 {
          top: 76px;
          right: 23px;
          transform: rotate(180deg);
        }

        .arrow3 {
          top: 33px;
          right: 67px;
          transform: rotate(90deg);
        }

        .arrow4 {
          bottom: 9px;
          right: 67px;
          transform: rotate(-90deg);
        }

        .title {
          margin-bottom: 5px;
          width: 48px;
          height: 17px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 17px;
        }

        .shangUp {
          position: absolute;
          left: 37%;
          top: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .xaiUp {
          position: absolute;
          left: 37%;
          bottom: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .zuoUp {
          position: absolute;
          left: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .youUp {
          position: absolute;
          right: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
      }

      .control-speed {
        width: 100%;
        height: 30%;
        // background: #c9d3d3;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 20px;

        .title {
          margin-bottom: 0;
          color: #fff;
          width: 48px;
          height: 17px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          // color: #FFFFFF;
          line-height: 17px;
        }

        .content {
          width: 80%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 5px;


          .item {
            width: 28px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            cursor: pointer;
            // width: 10px;
            // height: 18px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            line-height: 18px;
          }

          .item-selected {
            color: #00F5FF;
            // border-bottom: 2px solid #00F5FF;
          }

          .divider {
            margin: 0 1px;
            background-color: #c0c0c0;
          }
        }

        .bottom-line {
          // width: 152px;
          width: 80%;
          height: 2px;
          background: transparent;
          border-radius: 4px;
          margin-top: 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .item {
            width: 28px;
            height: 2px;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            color: #10263B;
            ;
            // cursor: pointer;
            // width: 10px;
            // height: 18px;
            // font-size: 14px;
            // font-family: PingFangSC-Medium, PingFang SC;
            // font-weight: 500;
            // line-height: 18px;
          }

          .item-selected {
            // color: #00F5FF;
            // border-bottom: 2px solid #00F5FF;
            background-color: #00F5FF;
          }
        }
      }
    }
  }
}


::v-deep {
  .el-select {
    width: 165px;
    margin-right: 5px;

    .el-input {
      .el-input__inner {
        background: #000000;
        border: 1px solid #004fff;
        color: #dce9ff;
      }
    }
  }

  .el-input {
    width: 130px;
    margin-right: 5px;

    .el-input__inner {
      background: #000000;
      border: 1px solid #004fff;
      color: #dce9ff;
    }
  }

  .el-radio-group {
    .el-radio {
      .el-radio__input {
        &.is-checked {
          .el-radio__inner {
            border-color: #004fff;
            background: #004fff;
          }
        }
      }

      .el-radio__label {
        color: #cbd0eb;
      }
    }
  }

  .butto {
    width: 35px;
    height: 25px;
    font-size: 12px;
    line-height: 25px;
    text-align: center;
    background: #004fff;
    color: #000;
    border-radius: 5px;
  }

  .el-button {
    width: 35px;
    height: 25px;
    text-align: center;
    background: #2aefed;
    color: #000;
    border: none;
  }
}

/deep/.el-slider__bar {
  background: #fff;
}

.speedC {
  display: flex;
  width: 173px;
  height: 24px;
  background: rgba(13, 34, 79, 0.65);
  border-radius: 12px;
  border: 1px solid #427dff;

  .speed {
    width: 34px;
    // background: #FFFFFF;
    color: #ffffff;
    border-radius: 12px;
    text-align: center;
    line-height: 24px;
  }

  .pitch {
    font-size: 12px;
    margin-left: 12px;
    width: 34px;
    height: 24px;
    border-radius: 12px;
    text-align: center;
    line-height: 24px;
    color: #fff;
  }
}

.iconfont {
  color: #004fff;
}

// 变焦功能
/deep/ .el-slider__runway {
  visibility: visible;
  height: 3px;
}

/deep/ .el-slider__bar {
  height: 3px;
  background-color: #2B72EA !important;
}

/deep/ .el-slider__button {
  background-color: #007AFF;
  margin-top: -2px;
  border: 1px solid #fff;
}
</style>

<style lang="scss">
.form-item-shipinzhidian {
  .el-switch {
    // width: 60px;
    background: #2B4660 !important;
    border-radius: 15px;
    border: 0;
  }

  .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  .el-switch__label--right {
    z-index: 1;
    left: -3px;
  }

  .el-switch__label--left {
    z-index: 1;
    left: 19px;
  }

  .el-switch__label.is-active {
    display: block;
  }

  .el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 45px !important;
  }
}

.form-item-bianjiao {
  // background-color: yellow;

  .el-input {
    // width: 100% !important;
    width: 50px;
    height: 28px;
    background: #10263B;
    border-radius: 2px;
    margin-right: 0;

    .el-input__inner {
      width: 100%;
      height: 100%;
      border: 0;
      background-color: #10263B;
    }
  }
}
</style>
